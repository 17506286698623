@import "../colors/colors.module";
@import "../mixins";

.create-map-page {

  &_main-promo {
    p {
      margin-bottom: 1.25rem;
      font-size: 0.875rem;
    }

    &_title {
      margin-bottom: 1.25rem;
    }

    &_tagline {
      margin-bottom: 1.25rem;
    }

    &_selection {
      margin-bottom: 1.25rem;
    }

    &_no-promos {
      margin-top: 0.85rem;
      font-size: 0.875rem;
      color: $medium-gray;
      text-align: center;
    }

    &_table {
      display: table;
      width: 100%;

      &_header {
        display: table-header-group;
        background-color: $light-gray;

        &_row {
          display: table-row;

          &_item {
            @extend .sora_semi-bold;
            display: table-cell;
            padding: 0.62rem 1.25rem;
            vertical-align: middle;
            font-size: 0.875rem;
            color: $medium-gray;
          }
        }
      }

      &_body {
        display: table-row-group;

        &_row {
          display: table-row;

          &_item {
            max-width: 0;
            padding: 0.62rem 1.25rem;
            display: table-cell;
            vertical-align: middle;

            button {
              cursor: pointer;
            }

            svg {
              color: $medium-gray;
              font-size: 1.5rem;

              &.is-selected {
                color: $secondary-purple;
              }
            }

            &_truncate {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    &_description {
      margin-bottom: 1.25rem;
    }

    &_images,
    &_cart-images {
      margin-bottom: 1.25rem;

      &_row {
        display: flex;
        gap: 1.25rem;

        p {
          margin-bottom: 0;
          font-size: 0.75rem;
        }

        &_hero {
          flex: 1;
        }

        &_background {
          flex: 2;
        }
      }
    }
  }
}

.bg-color-editing {
  background-color: $light-gray;
}
