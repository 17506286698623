.login-page {
  @extend .mobile-force-container;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  min-height: 100%;

  &_spacer-top {
    padding-top: 21px;
  }

  &_content {
    &_title {
      margin-bottom: 35px;
    }

    &_email-container {
      margin-bottom: 16px;
    }

    &_password-container {
      margin-bottom: 24px;

      &_label-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &_toggler {
          @extend .p2-sub-body;
          margin-bottom: 0;
        }
      }
    }

    &_login-button {
      width: 100%;
    }

    &_other-actions {
      margin-top: 42px;

      &_forgot {
        @extend .p2-sub-body;
        text-align: center;
        margin-bottom: 16px;
      }

      &_sign-up {
        @extend .p2-sub-body;
        text-align: center;
        margin-bottom: 32px;
      }

      &_owner {
        @extend .p2-sub-body;
        text-align: center;
      }
    }

    &_legal {
      margin-top: 35px;

      &_p {
        margin-top: 24px;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}
