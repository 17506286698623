@import "../colors/colors.module";
@import "../mixins";

// Old styles
.restaurant-update-hours-page {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &_title {
    line-height: 2;
    text-align: center;
    font-size: 1.5rem;
  }

  &_field {
    margin-bottom: 1rem;
  }

  &_submit-button {
    width: 100%;
  }

  &_switch-text {
    font-size: 0.875rem;
  }

  &_hours-container {
    margin-top: 2rem;
  }
}

// Updated styles
.business-page-hours {
  margin-top: 1rem;
  width: 100%;

  &_section {
    border-radius: 1rem;
    border: 0.0625rem solid $lighter-gray;
    padding: 0.8125rem 1.125rem;
    flex-grow: 1;
    max-width: 50%;

    label {
      font-size: 0.875rem;
      color: $darker-gray;
    }

  }

  &_options {
    display: flex;
    gap: 1.5rem;
  }

  &_suspend-until-container {
    margin-top: 1rem;
  }

}