.frame-one-accordions {
  border-top: 1px solid $ui-gray;

  &_item {
    border-bottom: 1px solid $ui-gray;

    &.is-active {
      background-color: $ui-gray;
      padding-bottom: 1rem;
      transition: background-color 0.2s;
    }

    &_title {
      @extend .reset-button;

      cursor: pointer;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1rem;
      padding: 1rem;
    }

    &_body {
      overflow: hidden;
      transition: height 0.2s ease-in-out;

      &_inner {
        padding: 1px 1rem; // 1px top & bottom to prevent collapsing margins
      }
    }
  }
}
