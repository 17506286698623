.search-input {
  position: relative;

  svg {
    display: block;
    position: absolute;
    //z-index: 5;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }

  input {
    padding-left: 3rem;
  }

  &_clearer {
    position: absolute;
    top: $input-border-width;
    right: 0.5rem;
    width: 3rem;
    height: calc(100% - calc(#{$input-border-width} * 2));
    border-radius: $input-border-radius;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      @include svg-sizer(24px);
      padding: 4px;
      cursor: pointer;
      transition: all 0.3s;
      opacity: 0.8;

      &:hover {
        opacity: 1;
        color: $black;
      }
    }
  }
}