.business-page_section-tabs {
  margin-top: 2rem;
  width: 100%;
  border-bottom: 0.0625rem solid rgba($subtitle-gray, 0.5);
  display: flex;
  flex-direction: row;
  gap: 2rem;

  &_tab {
    cursor: pointer;
    border-bottom: 0.0625rem solid transparent;
    padding-bottom: 0.5rem;
    transition: all 0.3s;

    & > span {
      @extend .sora_medium;
      font-size: 14px;
      color: $subtitle-gray;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    &:hover {
      border-bottom: 0.0625rem solid $black;
      opacity: 0.6;

      span {
        color: $black;
      }
    }

    &.is-active {
      border-bottom: 0.0625rem solid $black;

      &:hover {
        opacity: 1;
      }

      span {
        color: $black;
      }
    }

    &_count {
      font-size: 0.75rem;
      background-color: $ui-gray;
      border-radius: 50%;
      width: 1.25rem;
      height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}