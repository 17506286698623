.frame-one-address-input {
  position: relative;
  z-index: 0;

  &_manual-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  &_row {
    display: flex;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  &_field {
    flex: 1;
  }
}
