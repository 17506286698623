@import "../colors/colors.module";
@import "../mixins";

.menu-order-modal {
  &_row {
    display: flex;
    gap: 1rem;

    &_order-items {
      flex: 1;
    }

    &_info {
      flex: 1;
    }
  }
}

.menu-order-pricing {
  &_row {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid $ui-gray;
    padding: 0.5rem 0;

    &_value {
      white-space: nowrap;
    }
  }

  &_discounts {
    &_item {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      border-bottom: 1px solid $ui-gray;
      padding: 0.5rem 0 0.5rem 2rem;

      &_value {
        white-space: nowrap;
        color: $error;
      }
    }
  }

  &_service-fees {
    &_item {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      border-bottom: 1px solid $ui-gray;
      padding: 0.5rem 0;
    }
  }
}
