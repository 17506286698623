@import "../colors/colors.module";
@import "../mixins";

.upload-image-preview {
  border: 2px dashed $medium-gray;
  position: relative;
  height: 17.4375rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-color: $light-gray;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100% !important;

  &.has-image {
    border: 0;
  }

  &.is-toast-preview {
    height: 12.5rem !important;
  }

  &_icon {
    width: 2.375rem;
    height: 2rem;
    color: $medium-gray;
  }

  &_optional {
    margin-bottom: 0;
    font-size: 0.75rem;
    color: $medium-gray;
  }

  &_button {
    color: $secondary-purple;
    border: none;

    svg {
      min-width: 0.5rem;
      min-height: 0.5rem;
      max-width: 0.5rem;
      max-height: 0.5rem;
    }

  }
}
