.service-availabilities-input {

  &_save-container {
    display: flex;
    margin-bottom: 1rem;
    button {
    }
  }

  &_bd-flex {
    display: flex;
    flex-wrap: wrap;
  }

  &_days-container {
    &_block {
      padding-top: 1rem;

      &_closed {
        padding: 1rem 0;
      }

      &_no-hours {
        padding: 1rem 0;
        border-bottom: 1px solid $ui-gray;
      }

      ul {
        @extend .reset-list;

        li {
          padding: 1rem 0;
          border-bottom: 1px solid $ui-gray;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  &_bd-days-container {
    @extend .sora_medium;
    flex-grow: 1;
    width: 50%;
    margin-bottom: 2.5rem;

    &_day-row {
      display: flex;
      align-items: center;
      gap: 2rem;

      p {
        @extend .sora_medium;
        margin-bottom: 0;
      }


    }

    &_closed {
      margin-top: 1rem;
      padding: 1rem;
      background-color: $light-gray;
      border-radius: 1rem;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $medium-gray;

      p {
        margin-bottom: 0;
        font-size: 0.875rem;
      }

      &_icon {
        height: 1.5rem;
        width: 1.5rem;
      }

    }

    &_dates {
      display: flex;
      align-items: center;
      gap: 1rem;

      &_remove {
        height: 2rem;
        width: 2rem;
        margin-top: 1rem;
      }

      &_remove-active {
        color: red;
        transition: all 0.2s;

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }

      }

      &_remove-disabled {
        color: $subtitle-gray;

        &:hover {
          cursor: not-allowed;
        }

      }

      &_inputs {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        input {
          &:disabled {
            width: 12rem;
            background-color: $light-gray;
            height: 2.5rem;
          }
        }

        &_horizontal {
          height: 0.078rem;
          background-color: $medium-gray;
          width: 1.5rem;
        }

        &_save {
          color: $secondary-purple;
          margin-left: 1rem;
          height: 2rem;
          width: 2rem;
          transition: all 0.2s;

          &:hover {
            cursor: pointer;
            transform: scale(1.1);
          }
        }

      }

    }

    &_add-new {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: fit-content;
      transition: all 0.2s;
      margin-top: 1rem;

      &_enabled {
        color: $secondary-purple;
      }

      &_disabled {
        color: $subtitle-gray;
      }

      &_warning {
        color: red;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }

      &_icon {
        height: 1rem;
        width: 1rem;
      }

      p {
        margin-bottom: 0;
        font-size: 0.875rem;
      }

    }

    &_active-inputs {
      width: 12rem;
      height: 2.5rem;
    }

  }

}

.business-hours-modal {
  &_row {
    display: flex;
    gap: 2rem;
  }

  &_field {
    flex: 1;
    margin-bottom: 1rem;
  }
}
