$frame-one-button-height: 48px;
$frame-one-button-height-large: 64px;
$frame-one-button-height-narrow: 36px;

.frame-one-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 16px;
  padding: 10px 1.75rem;
  min-height: $frame-one-button-height;
  border: none;
  transition: all 0.3s;
  text-decoration: none !important;
  cursor: pointer;
  width: fit-content;
  user-select: none;

  &_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  span {
    @extend .sora_medium;
  }

  svg {
    @include svg-sizer(18px);
    background-color: transparent;
  }

  &_left-icon {
    margin-right: 0.5em;
  }

  &_right-icon {
    margin-left: 0.5em;
  }

  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
  }

  &_size {
    &_large {
      font-size: 16px;
      border-radius: 32px;
      padding: 10px 2.25rem;
      min-height: $frame-one-button-height-large;

      span {
        @extend .sora_semi-bold;
      }
    }

    &_narrow {
      font-size: 14px;
      border-radius: 16px;
      padding: 10px 1.5rem;
      min-height: $frame-one-button-height-narrow;

      span {
        @extend .sora_medium;
      }
    }

    &_icon {
      border-radius: 50%;
      padding: 0;
      width: 48px;
      height: 48px;

      .frame-one-button_left-icon,
      .frame-one-button_right-icon {
        margin: 0;
      }
    }

    &_icon-square {
      padding: 0;
      width: 48px;
      height: 48px;

      .frame-one-button_left-icon,
      .frame-one-button_right-icon {
        margin: 0;
      }
    }
  }

  &_color {

    &_purple {
      color: $white;
      background-color: $secondary-purple;

      &:hover {
        color: $white;
        background-color: $primary-purple;
      }
    }

    &_dark {
      color: $white;
      background-color: #333;

      &:hover {
        color: $white;
        background-color: #666;
      }
    }

    &_purple-outline {
      color: $secondary-purple;
      border: 1px solid $secondary-purple;
      background-color: $white;

      &:hover {
        color: #5B42B1;
        border-color: #5B42B1;
        background-color: #F9F8FE;
      }
    }

    &_gray {
      color: #08080A;
      background-color: #F0F0F1;

      &:hover {
        background-color: #E0E0E1;
      }
    }

    &_ghost {
      color: #08080A;
      background-color: #FFFFFF;

      &:hover {
        background-color: #F0F0F1;
      }
    }

    &_white-drop-shadow {
      color: #08080A;
      background-color: $white;
      border: 1px solid #E0E0E1;

      &:hover {
        background-color: #F7F7F8;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
    &_white-drop-shadow-devour {
      background-color: $white;
      border: 1px solid #E0E0E1;
      color: #9E8DB6;
      &:hover {
        background-color: #F7F7F8;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
    &_warning {
      color: $white;
      background-color: $warning;

      &:hover {
        color: $white;
        background-color: lighten($warning, 10%);
      }
    }

    &_danger {
      color: $white;
      background-color: $error;

      &:hover {
        color: $white;
        background-color: lighten($error, 10%);
      }
    }

    &_success {
      color: $white;
      background-color: $success;

      &:hover {
        color: $white;
        background-color: lighten($success, 10%);
      }
    }


  }
}
