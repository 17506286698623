@import "../colors/colors.module";
@import "../mixins";

.business-card {
  border: 1px solid $ui-gray;
  padding: 1rem;
  text-align: center;
  height: 100%;

  &.is-inactive {
    background-color: lighten($error, 30%);
  }

  a {
    text-decoration: none;
  }

  &_header {
    position: relative;
    z-index: 0;
    margin-bottom: 3rem;

    &_image {
      position: relative;
      border-radius: 1rem;
      overflow: hidden;

      img {
        width: 100%;
        aspect-ratio: 10 / 3;
        object-fit: cover;
        object-position: 50% 50%;
        vertical-align: top;
      }
    }

    &_inactive {
      position: absolute;
      z-index: 6;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &_text {
        background-color: $warning;
        padding: 0.25rem 1rem;
        border-radius: 1rem;
      }
    }

    &_logo {
      position: absolute;
      border-radius: 1rem;
      overflow: hidden;
      z-index: 5;
      bottom: -2rem;
      left: 50%;
      margin-left: -2rem;
      border: 3px solid $white;

      img {
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        object-position: 50% 50%;
        vertical-align: top;
      }
    }
  }
}
