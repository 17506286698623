.forgot-password {
  @extend .mobile-force-container;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  min-height: 100%;

  &_spacer-top {
    padding-top: 21px;
  }

  &_content {
    &_title {
      margin-bottom: 35px;
    }

    &_email-container {
      margin-bottom: 24px;
    }

    &_submit-button {
      width: 100%;
    }

    &_other-actions {
      margin-top: 42px;

      &_sign-up {
        @extend .p2-sub-body;
        text-align: center;
        margin-bottom: 16px;
      }

      &_log-in {
        @extend .p2-sub-body;
        text-align: center;
        margin-bottom: 16px;
      }

      &_owner {
        @extend .p2-sub-body;
        text-align: center;
      }
    }
  }
}
