.business-add-user-modal {
  &_header {
    display: flex;
    align-items: center;
    padding: 1rem;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
    h5 {
      flex: 1;
      text-align: center;
      margin-bottom: 0;
      padding-right: 1.25rem;
    }
  }

  &_body {
    background-color: $ui-gray;
    padding: 0.35rem 0 0 0;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;

    &_field {
      margin-bottom: 0.35rem;
      background-color: $white;
      padding: 1rem;

      label {
        @extend .sora_semi-bold;
        color: $darker-gray;
      }

      input {
        height: 2.5rem;
      }

      &.permissions-list {
        padding-bottom: 0;
      }

      &_permission {
        border-top: 0.0625rem solid $ui-gray;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 0;

        &.is-disabled {
          cursor: not-allowed;
          opacity: 0.6;
        }

        span {
          font-size: 0.875rem;
          color: $darker-gray;
        }

        &_input {
          display: flex;
          align-items: center;
          gap: 1rem;
        }
      }
    }

    &_buttons {
      background-color: $white;
      padding: 1rem;
      display: flex;
      align-items: center;

      &_left {
        @extend .sora_semi-bold;
        font-size: 0.875rem;
        color: #EE4032;
        display: flex;
        align-items: center;
        gap: 0.65rem;
        cursor: pointer;
      }

      &_right {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 1.25rem;

        &_cancel {
          @extend .sora_semi-bold;
          font-size: 0.875rem;
          color: #EE4032;
          cursor: pointer;
        }

        &_save {
          padding-left: 2rem;
          padding-right: 2rem;
        }
      }
    }
  }
}