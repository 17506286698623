body {
  @extend .sora;
  height: 100vh;
  margin: 0;
  overflow-x: hidden;
  background-color: $white;
  color: #403E48;
}

body.mobile-sidebar-open {
  overflow-y: hidden;
}
