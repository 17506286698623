.merchant-brand-title-description {
  max-width: 60rem;
  margin: 1.5rem auto;

  h4 {
    margin: 3rem 0 1.25rem 0;
  }

  &_content {
    padding: 2.5rem;
    background-color: white;
    border-radius: 1rem;

    &_title-container {
      display: flex;
      align-items: center;
      gap: 0.625rem;
    }

    &_title {
      @extend .sora_semi-bold;
      margin-bottom: 0;
    }

    &_tag {
      border-radius: 6.25rem;
      padding: 0.375rem 0.625rem;
      background-color: $light-gray;
      width: fit-content;

      p {
        font-size: 0.75rem;
        color: $medium-gray;
        margin-bottom: 0;
      }
    }

    &_subtitle {
      margin-top: 0.85rem;
      font-size: 0.875rem;
      color: $medium-gray;
    }

  }

}

.merchant-brand-handle-name {

  &_url-preview {
    margin-top: 1.25rem;

    &_subheader {
      color: $medium-gray;
      font-size: 0.75rem;
    }

    &_container {
      border: 0.063rem solid $light-gray;
      padding: 0.25rem 1.25rem;
      border-radius: 1rem;

      p {
        font-size: 0.875rem;
        color: $medium-gray;
        margin-bottom: 0;
      }
    }

  }
}

.merchant-brand-upload-image {
  position: relative;
  height: 15.5625rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &_icon {
    width: 2.375rem;
    height: 2rem;
    color: $medium-gray;
  }

  &_subtitle {
    margin-bottom: 0;
    font-size: 0.75rem;
    color: $medium-gray;
  }

  &_button {
    color: $secondary-purple;
    border: none;

    svg {
      min-width: 0.5rem;
      min-height: 0.5rem;
      max-width: 0.5rem;
      max-height: 0.5rem;
    }

  }

  &_triangle-left {
    z-index: 1;
    width: 0;
    height: 0;
    position: absolute;
    top: 11.82rem;
    border-top: 6.25rem solid transparent;
    border-left: 46rem solid white;
    left: 0;
  }

  &_triangle-right {
    z-index: 1;
    width: 0;
    height: 0;
    position: absolute;
    top: 11.82rem;
    right: 0;
    border-top: 6.25rem solid transparent;
    border-right: 46rem solid white;
  }
}

.merchant-brand-page-theme {
  &_top {
    display: flex;
    gap: 2.5rem;
    width: 100%;

    &_section {
      width: 50%;
    }

    &_vertical {
      width: 0.0625rem;
      background-color: $ui-gray;
    }

    &_switch {
      @extend .sora_medium;
      height: 3.125rem;
    }

    &_input-container {
      position: relative;
      display: inline-block;

      &_picker {
        position: absolute;
        opacity: 0;
        pointer-events: none;
      }

    }


  }

  &_preview {
    margin-top: 1rem;

    &_subheader {
      font-size: 0.75rem;
      color: $medium-gray;
    }

    &_container {
      height: 4.875rem;
      padding: 0.625rem;
      border: 0.125rem solid $ui-gray;
      border-radius: 1rem;

      &_contents {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 0.63rem;

        &_text {
          @extend .sora_semi-bold;
          margin-bottom: 0;
          text-align: center;
        }
      }

    }

  }

}

.merchant-brand-locations {
  &_top-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &_right {
      display: flex;
      align-items: center;
      gap: 0.625rem;

      button {
        height: 2.25rem;
        padding: 0.625rem 1.25rem;
      }

      &_reset {
        color: $secondary-purple;
        background-color: $light-lilac;
      }

    }

  }

  &_flex-row {
    display: flex;
    gap: 1.25rem;
  }

  &_name-width-adjust {
    min-width: 12rem !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_address-width-adjust {
    max-width: 30% !important;
  }

  &_default-button {
    background-color: $light-gray;
    padding: 0.375rem 0.625rem;
    width: fit-content;
    border-radius: 6.25rem;
    margin: 0 auto;

    p {
      font-size: 0.75rem;
      margin-bottom: 0;
      text-align: center;
    }
  }

  &_select-default-button {
    height: 2.25rem;
    padding: 0.625rem 1.25rem;
    font-size: 0.75rem;
    margin: 0 auto;
  }

}

.merchant-brand-buttons {
  max-width: 60rem;
  margin: 1.5rem auto;

  &_container {
    display: flex;
    gap: 0.625rem;
    width: 100%;
    align-items: flex-end;
  }

}

.merchant-brand-margin-bottom-adjust {
  margin-bottom: 3rem;
}

.merchant-brand-disable-select {
  &:hover {
    cursor: not-allowed;
  }
}