.confirm-password-reset-request-modal {
  .frame-one-modal_header_title-row_title {
    @extend h3;
    margin-bottom: 0;
  }

  &_body {
    &_code-container {
      margin-bottom: 16px;
    }

    &_new-pass-container {
      margin-bottom: 16px;
    }

    &_confirm-pass-container {
      margin-bottom: 24px;
    }

    &_submit-button {
      width: 100%;
    }
  }
}
