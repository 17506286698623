.frame-one-icon-input {
  position: relative;

  &_icon-container {
    position: absolute;
    top: $input-border-width;
    right: calc(#{$input-horizontal-padding} / 2);
    padding-right: calc(#{$input-horizontal-padding} / 6);
    padding-left: calc(#{$input-horizontal-padding} / 6);
    height: calc(100% - calc(#{$input-border-width} * 2));
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    &_icon {
      @include svg-sizer(80%);
      transition: all 0.2s;
      padding: 4px;

      &_hover-enabled {
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  &_disabled {
    .frame-one-icon-input_icon-container {
      background-color: #F7F7F8;
    }
  }
}
