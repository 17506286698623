.business-page-children-locations {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 2rem;

  &_form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
  }

  &_update-button {
    align-self: center;
  }

  &_modal {
    .frame-one-modal_header_title-row_title {
        font-size: 1.25rem;
        font-weight: 500;
    }
  }
}