.manage-brand-maps {
  @extend .container;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &_spacer-top {
    margin-bottom: 3rem;
  }

  &_content {
    &_loading {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 2rem;
    }

    &_header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;

      h3 {
        text-align: center;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;

        h3 {
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .frame-one-button {
          flex-shrink: 0;
        }
      }
    }

    &_map-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex-wrap: wrap;
      padding-bottom: 2rem;

      &_card {
        width: 100%;
      }

      &_empty {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        &_icon {
          @include svg-sizer(80px);
          color: rgba(black, 30%);
        }

        &_message {
          margin-top: 1rem;
          text-align: center;
        }
      }
    }
  }
}