@import "../colors/colors.module";
@import "../mixins";

.business-update-images-page {
  @extend .mobile-force-container;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &_spacer-top {
    margin-bottom: 2rem;
  }

  &_title {
    line-height: 2;
    text-align: center;
    font-size: 1.5rem;
  }

  &_image-card {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $ui-gray;
  }

}
