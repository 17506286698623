@use "sass:map";
@import "../colors/colors.module";

/* Page Layout & Sidebar */
$frame-one-grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
);

$f1-sidebar-width: 258px; // Sidebar width for mobile
$f1-sidebar-width-docked: 258px; // Sidebar width for when/if it becomes docked via $f1-sidebar-docked-at variable
$f1-sidebar-docked-at: map-get($frame-one-grid-breakpoints, xl); // Configure when/if sidebar should dock at certain threshold; -1px for never docked

$frame-one-container-max-widths: (
        sm: 540px,
        md: 780px,
        lg: 960px,
        xl: 1140px,
        xxl: 1200px,
        xxxl: 1240px,
);

/* Modal Variables */
$frame-one-modal-transition-timing: 350ms;

/* Export any variables that are needed in our React components or Typescript utils */
:export {
  f1-sidebar-width: $f1-sidebar-width;
  f1-sidebar-width-docked: $f1-sidebar-width-docked;
  f1-sidebar-docked-at: $f1-sidebar-docked-at;

  frame-one-modal-transition-timing: $frame-one-modal-transition-timing;
}
