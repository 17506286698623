.restaurant-map-landing {
  $root: &;

  &_footer {
    margin-top: 3.75rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;

    @include media-breakpoint-up(sm) {
      margin-top: 5rem;
      align-items: flex-start;
      gap: 0.625rem;
    }

    &_logo {
      margin-left: -5px;

      img {
        display: block;
        width: 10rem;
      }
    }

    &_disclaimer {
      color: $light-gray;
      width: 100%;
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2.5rem;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: space-between;
      }

      #{$root}.theme-DARK & {
        color: $medium-gray;
      }

      &_options {
        a {
          color: inherit;
          text-decoration: none;
          pointer-events: none;
        }

        @include media-breakpoint-down(xs) {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.25rem;

          span {
            display: none;
          }
        }
      }
    }
  }

}