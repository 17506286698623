.new-project-placeholder-content {
  @extend .container;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_logo {
    width: 600px;
    max-width: 100%;
    padding: 0 1.5rem;
    margin-top: -3rem;
    margin-bottom: 3rem;
  }

  &_title {
    width: 100%;
    text-align: center;
    padding: 0 1.5rem;
    margin-bottom: 3rem;
  }
}
