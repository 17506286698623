.business-page_menus {
  padding: 2rem 0 3rem;

  &_content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 7.5rem;
  }

  &_category {
    &_header {
      margin-bottom: 1.25rem;
      display: flex;
      align-items: center;
      gap: 0.625rem;
      font-weight: 600;
      cursor: pointer;
    }

    &_subcategory {
      padding-left: 1.25rem;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }

    &_list {
      padding: 1.25rem;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem;
      background-color: $light-gray;
      border-radius: 0.25rem;
      border: 0.125rem solid $white;

      &_item {
        background-color: $white;
        padding: 0.625rem 1.25rem;
        text-align: center;
        border-radius: 0.625rem;
        box-shadow: 0 0.0625rem 0.125rem 0 rgba(16, 24, 40, 0.06), 0 0.0625rem 0.1875rem 0 rgba(16, 24, 40, 0.10);
        cursor: pointer;
      }
    }
  }
}