@import "../colors/colors.module";
@import "../mixins";

.frame-one-table-container {
  max-width: 100%;
  overflow-x: auto;

  &_table {
    border-top: 1px solid $ui-gray;
    border-left: 1px solid $ui-gray;
    border-right: 1px solid $ui-gray;
    border-spacing: 0;
    min-width: 100%;

    &_header {
      &_row {
        background-color: $light-gray;
      }

      &_cell {
        @extend .sora_medium;

        border-bottom: 1px solid $ui-gray;
        text-align: left;
        color: $medium-gray;
        padding: 0.5rem 1rem;

        .is-sortable {
          cursor: pointer;
          user-select: none;

          display: flex;
          gap: 0.3rem;

          svg {
            color: $medium-gray;
          }

          &.is-sorted {
            svg {
              color: $black;
            }
          }
        }
      }
    }

    &_body {
      &_row {
        &_cell {
          border-bottom: 1px solid $ui-gray;
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}
