.merchant-agreement-terms-modal {
  .frame-one-modal_header_title-row_title {
    @extend h3;
    margin-bottom: 0;
  }

  &_body {
    .frame-one-checkbox_real-label {
      line-height: 26px;
    }

    &_message {
      a {
        transition: opacity 0.2s;
        color: $secondary-purple;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
