a {
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  transition: color 200ms;

  &:hover {
    text-decoration: underline;
    color: $secondary-purple;
  }
}

a.text-underline {
  text-decoration: underline;
}

span.a-like {
  @extend a;
}
