.business-stripe-modal {
  &_field {
    margin-bottom: 2rem;
  }
  &_business {
    margin-bottom: 2rem;
  }
  &_review {
    display:flex;
    gap: 1rem;
    margin-bottom: 2rem;

    &_current {
      flex: 1;
    }
    &_new {
      flex: 1;
    }
  }
  &_submit-button {
    width:100%;
  }
}

