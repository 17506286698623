.forgot-password-reset-success-modal {
  &_body {
    &_check-con {
      display: flex;
      justify-content: center;
      padding-top: 2rem;
      margin-bottom: 2rem;

      svg {
        @include svg-sizer(90px);
        color: $success;
      }
    }

    &_success-msg {
      text-align: center;
    }

    &_button {
      width: 100%;
    }
  }
}
