// Adding a new color...
// 1. Add to the "Project specific...", section
// 2. Add to the theme-colors map
// 3. Add to the :export statement
// 4. Add to the interface in colors.ts

/* Standard Color Declarations */
$black: #000000;
$white: #FFFFFF;

/* Project specific color definitions, replace and update as needed */
$accent-navy: #2B1D52;
$primary-purple: #452D7A;
$light-purple: #C2B6F4;
$secondary-purple: #7859E6;
$blue-purple: #657AE1;
$brighter-purple: #804FD3;
$gloomy-purple: #8268E2;
$royal-purple: #5c42b1; 
$ui-gray: #F0F0F1;
$light-lilac: #F9F8FE;
$light-gray: #F7F7F8;
$lighter-gray: #E0E0E1;
$subtitle-gray: #A8A8AD;
$medium-gray: #87878D;
$slate-gray: #616169;
$dark-gray: #403E48;
$darker-gray: #08080A;
$twitter: #1DA1F2;
$soft-lilac: #DED6F8;
$light-lilac: #F9F8FE;
$light-gray: #F7F7F8;
$medium-gray: #87878D;
$warning: #FFEE3F;
$error: #FF4238;
$success: #11D66D;

/* theme-colors map, remnant of Bootstrap approach, still used for our checkboxes */
$theme-colors: (
        "black": $black,
        "white": $white,
        "navy": $accent-navy,
        "primary-purple": $primary-purple,
        "light-purple": $light-purple,
        "secondary-purple": $secondary-purple,
        "blue-purple": $blue-purple,
        "brighter-purple": $brighter-purple,
        "royal-purple": $royal-purple,
        "gloomy-purple": $gloomy-purple,
        "ui-gray": $ui-gray,
        "light-gray": $light-gray,
        "lighter-gray": $lighter-gray,
        "medium-gray": $medium-gray,
        "soft-lilac": $soft-lilac,
        "light-lilac": $light-lilac,
        "slate-gray": $slate-gray,
        "subtitle-gray": $subtitle-gray,
        "dark-gray": $dark-gray,
        "darker-gray": $darker-gray,
        "warning": $warning,
        "error": $error,
        "success": $success,

);

/* Export for Typescript / React JSX implementation */
:export {
  black: $black;
  white: $white;

  accent-navy: $accent-navy;
  primary-purple: $primary-purple;
  light-purple: $light-purple;
  secondary-purple: $secondary-purple;
  royal-purple: $royal-purple;
  lighter-gray: $lighter-gray;
  ui-gray: $ui-gray;
  subtitle-gray: $subtitle-gray;
  $dark-gray: #403E48;
  $twitter: #1DA1F2;
  soft-lilac: $soft-lilac;
  warning: $warning;
  error: $error;
  success: $success;
}
