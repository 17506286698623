p {
  @extend .sora;
  font-size: 16px;
  line-height: 21px;
}

.p2-sub-body {
  @extend .sora;
  font-size: 14px;
  line-height: 18px;
}

.p3-caption {
  @extend .sora;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1.2px;
}

span {
  @extend .sora;
}

button {
  @extend .sora;
}

label {
  @extend .sora;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0.5rem;
  cursor: text;
}

hr {
  border-top: 1px solid #E4E4E5;
}

.reset-list {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin: 0;
    padding: 0;
  }
}

.reset-button {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
}

.disable-cursor-reset {
  &:hover {
    cursor: not-allowed;
  }
}

.reset-link {
  @extend .reset-button;
  @extend a;
}

.hide-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

