@import "../colors/colors.module";
@import "../mixins";

.itsacheckmate-auth-page {
  margin-top: 3rem;
  margin-bottom: 3rem;

  &_content {
    @extend .container;
  }

  &_logo {

    &_img {
      max-width: 100%;
      max-height: 3rem;
    }
  }

}
