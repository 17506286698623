@import url("https://use.typekit.net/ekr6mve.css");
@import url("https://use.typekit.net/bur3yyl.css");

@mixin owners($font-weight: 400) {
  font-family: "owners", sans-serif;
  font-style: normal;
  font-weight: $font-weight;
}

@mixin owners-xnarrow($font-weight: 400) {
  font-family: "owners-xnarrow", sans-serif;
  font-style: normal;
  font-weight: $font-weight;
}

@mixin owners-narrow($font-weight: 400) {
  font-family: "owners-narrow", sans-serif;
  font-style: normal;
  font-weight: $font-weight;
}

.owners {
  @include owners;
}

.owners_regular {
  @include owners(400);
}

.owners_bold {
  @include owners(900);
}

.owners-xnarrow {
  @include owners-xnarrow;
}

.owners-xnarrow_regular {
  @include owners-xnarrow(400);
}

.owners-xnarrow_bold {
  @include owners-xnarrow(900);
}

.owners-narrow_regular {
  @include owners-narrow(400);
}

.owners-narrow_bold {
  @include owners-narrow(900);
}