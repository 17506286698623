.devour-sidebar {
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  border-right: 1px solid #E0E0E1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 12px;

  &_top {
    &_branding {
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        height: 40px;
      }
    }

    &_navigation {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1.5rem;

      &_button {
        width: 100%;
        padding: 10px 1.25rem;

        span {
          width: 100%;
          text-align: left;
          padding-left: 0.5rem;
        }

        &_selected {
          background-color: #F0F0F1;
        }
      }
    }
  }

  &_bottom {
    &_socials {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: 0.5rem;

      &_btn {
        width: 42px;
        height: 42px;
        min-height: 42px;
      }
    }
  }


}
