@import "../colors/colors.module";
@import "../mixins";

.business-manage-users-page {
  @extend .container;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &_spacer-top {
    margin-bottom: 2rem;
  }

  &_title {
    line-height: 2;
    text-align: center;
    font-size: 1.5rem;
  }

  &_add-another {
    margin-bottom: 1rem;
  }

  &_table {
    width: 100%;

    &_header {
      &.is-disabled {
        color: #999;
        cursor: not-allowed;
      }
    }

    &_cell {
      &.is-disabled {
        cursor: not-allowed;
      }
    }

    &_cell {
      border-top: 1px solid $ui-gray;
    }
  }
}
