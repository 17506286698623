.business-page_contact-info {
  display: flex;
  gap: 1rem;
  padding: 1.5rem 0 3rem;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & > div {
      border-radius: 1rem;
      border: 0.0625rem solid $lighter-gray;
      padding: 0.8125rem 1.125rem;

      label {
        font-size: 0.875rem;
        color: $darker-gray;
      }

      input, select {
        height: 2.5rem;
        font-size: 0.875rem;
        @extend .sora;
      }
    }
  }

  &_address {
    .frame-one-address-input {
      &_manual-button {
        min-height: unset;
        height: 2.15rem;
      }
    }
  }
  &_handoff {
    .frame-one-checkbox {
      &:hover {
        opacity: unset;
      }
      input:checked ~ .state {
        color: white;
        label::before {
          background-color: $secondary-purple;
          border: none;
        }
        .svg {
          stroke-width: 0.2125rem;
          z-index: 1 !important;
        }
      }
    }
  }

  &_special-instructions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }
  }
}
