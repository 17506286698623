.frame-one-react-select {
  div[class*="-control"] {
    height: 42px; // todo variable
    border: 1px solid #ced4da !important; // todo variable
    border-radius: 8px; // todo variable
    transition: all 0.3s;
  }

  input {
    height: 100%;
  }

  div[class*="-placeholder"] {
    font-weight: normal;
    height: 100% !important;
    display: flex;
    align-items: center;
  }

  div[class*="-ValueContainer"] {
    white-space: nowrap;
    padding-left: 0.75rem;
  }

  div[class*="-Input"] {
    input {
      &:focus {
        box-shadow: none;
      }
    }
  }

  div[class*="-indicatorContainer"] {
    //padding-left: $input-padding-x;
  }

  &_focused {
    div[class*="-control"] {
      box-shadow: 0 0 0 0.2rem rgba($secondary-purple, 0.35);
      border-color: $secondary-purple !important;
    }
  }
}
