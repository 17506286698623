@import "../colors/colors.module";
@import "../mixins";

.business-add-user-modal {
  &_email-container {
    margin-bottom: 2rem;
  }

  &_permissions-container {
    .frame-one-checkbox {
      margin-bottom: 1rem;
    }
  }

  &_warning {
    background-color: rgba($warning, 0.6);
    padding: 1rem;
    margin-bottom: 1rem;

    p {
      margin: 0;
    }
  }
}

.new-merchant-user-modal {
  &_field {
    margin-bottom: 2rem;
  }
}
