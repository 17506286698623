@import "../colors/colors.module";
@import "../mixins";

.business-page_section-margin {
  max-width: 95rem !important;
  padding: 0 1.5rem;
  margin: 0 auto;

  @include media-breakpoint-up(sm) {
    padding: 0 2.5rem;
  }
}

.business-details-page {
  position: relative;
  padding-bottom: 3rem;

  .business-page {

    &_save-contact-button {
      padding-left: 2rem;
      padding-right: 2rem;
      margin-left: auto;
      margin-top: 1.5rem;
    }

    &_taxonomies,
    &_menu-orders {
      padding: 1.5rem 0 3rem;

      &_header{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      select {
        height: 2.5rem;
      }

      &_header {
        padding: 0.5rem 1rem;
        border-top: 1px solid $ui-gray;
        border-left: 1px solid $ui-gray;
        border-right: 1px solid $ui-gray;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
      }
      &_search {
        position: relative;
        color: $subtitle-gray;
        flex: 1;

        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 1rem;
        }

        input {
          max-width: 34rem;
          height: 2.5rem;
          padding-left: 3rem;
          border-color: $ui-gray;

          &:focus {
            background-color: white;
          }
        }
      }

      &_header {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
      }

      &_container {
        margin-top: 1.5rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;

        &_option {
          @extend .sora_medium;
          height: 4.5rem;
          padding-left: 1rem;
          background-color: $light-gray;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          color: #08080A;
          transition: all 0.3s;

          &.is-selected {
            background-color: $secondary-purple;
            color: $white;
          }

          .frame-one-checkbox {
            &:hover {
              opacity: unset;
            }

            label::before {
              background-color: $white;
              border: none;
            }

            input:checked ~ .state {
              color: $secondary-purple;
              .svg {
                stroke-width: 0.2125rem;
                z-index: 1 !important;
              }
            }
          }
        }
      }

      &_status {
        display: block;
        padding: 0.25rem 0.5rem;
        border-radius: 0.5rem;
        text-align: center;
        font-size: 0.8rem;
        background-color: $ui-gray;
        color: $medium-gray;

        &.status-processing {
          background-color: lighten($warning, 20%);
          color: darken($warning, 40%);
        }

        &.status-complete {
          background-color: lighten($success, 20%);
          color: darken($success, 40%);
        }

        &.status-error {
          background-color: lighten($error, 20%);
          color: darken($error, 40%);
        }
      }
    }
  }

  .switch-input {
    &.pretty {
      width: 2rem;
    }

    &.pretty.p-switch .state:before {
      width: 3rem;
      height: 1.5rem;
    }

    &.pretty.p-switch.p-fill input:checked ~ .state:before {
      background-color: $secondary-purple !important;
    }

    &.pretty .state label:before,
    &.pretty .state label:after {
      top: -0.1rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    &.pretty.p-switch.p-fill input:checked ~ .state label:after {
      left: 1.5rem;
    }
  }
}
