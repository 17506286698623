@mixin gradient-text($gradient: null, $color: null) {
  @if $gradient {
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } @else if $color {
    background: none;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: $color;
  }
}