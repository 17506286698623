$phone-input-left-padding: 35%;
$phone-input-overlay-size: 32.5%;

.frame-one-phone-input {
  position: relative;

  &_text {
    position: absolute;
    top: 0;
    padding-left: max(140px, 32.5%);
  }

  .f1-select-wrapper {
    width: 32.5%;

    select {
      color: transparent;
      border-color: transparent;

      &:enabled:hover {
        border-color: transparent;
      }

      &:focus {
        box-shadow: none;
        border-color: transparent;
      }
    }

    &_icon-container {
      display: none;
    }
  }

  &_country-overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: max(140px, $phone-input-overlay-size);
    height: 100%;
    border-top-left-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
    padding-left: $input-horizontal-padding;
    padding-right: $input-horizontal-padding;
    display: flex;
    flex-direction: row;
    align-items: center;
    opacity: 0;
    transition: opacity 200ms;

    &_loaded {
      opacity: 1;
    }

    &_flag {
      height: $phone-input-overlay-size;
      object-fit: contain;
      margin-right: 5px;
    }

    &_chevron {
      margin-right: 10px;
      @include svg-sizer(18px);
    }

    &_dial-code {
      width: 100%;
      text-align: center;
      color: #08080A;
      font-size: 1rem;
    }
  }
}
