// Keep this number under control. It exponentially (x^2) increases the amount of CSS generated.
$maxSwitchOptions: 10;

.frame-one-switch-input {
  $root: &;

  position: relative;
  z-index: 0;
  background-color: #ddd;
  border-radius: 1rem;
  display: flex;

  &.is-disabled {
    cursor: not-allowed;
    background-color: #eee;
  }

  &_slider {
    position: absolute;
    transition: left .4s;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    @for $x from 1 through $maxSwitchOptions {
      // $x = number of options
      @for $y from 0 through $maxSwitchOptions - 1 {
        // $y = index of currently active option
        .input-options--#{$x}-#{$y} & {
          width: 100% / $x; // Width is 100% divided by number of options
          left: $y * (100% / $x); // Position is index multiplied by "width"
        }
      }
    }

    &_inner {
      position: absolute;
      display: block;
      box-shadow: 0 0 5px 0 rgba(100, 100, 100, 0.25);
      background-color: white;
      border-radius: 1rem;
      top: 0.25rem;
      left: 0.25rem;
      right: 0.25rem;
      bottom: 0.25rem;
    }
  }

  &_option {
    position: relative;
    z-index: 5;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 0.5rem 0;

    input[type=radio] {
      display: none;
    }

    &.is-disabled {
      cursor: not-allowed;
    }
  }
}
