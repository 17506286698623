.container-base {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  @extend .container-base;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: map-get($frame-one-container-max-widths, lg);
  }

  @include media-breakpoint-up(xl) {
    max-width: map-get($frame-one-container-max-widths, xl);
  }

  @include media-breakpoint-up(xxl) {
    max-width: map-get($frame-one-container-max-widths, xxl);
  }

  @include media-breakpoint-up(xxxl) {
    max-width: map-get($frame-one-container-max-widths, xxxl);
  }
}

.mobile-force-container {
  @extend .container-base;
  max-width: 460px;
}
