.frame-one-modal-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all $frame-one-modal-transition-timing;
  background-color: transparent;

  &_closed {
    display: none;
  }

  &_open {
    display: initial;
  }

  &_shader {
    background-color: rgba($black, 0.25);
  }

  &_inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    overflow-y: scroll;
    padding-left: 10px;
    padding-right: 10px;

    &_spacer {
      height: 100px;
    }

    &_content {
      background-color: $white;
      width: 100%;
      transition: all $frame-one-modal-transition-timing;
      opacity: 0;
      margin-top: -120px;
      z-index: 1;

      &_size-xs,
      &_size-max {
        max-width: 100%;
      }

      &_open {
        opacity: 1;
        margin-top: 0;
      }
    }

    &_clicker {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: transparent;
    }
  }
}

$frame-one-modal-sizes: (
  xs: 369px,
);

$frame-one-modal-sizes: map-merge($frame-one-container-max-widths, $frame-one-modal-sizes);

// Generate classNames for each container max-width for our modal content
@each $size, $breakpoint in $frame-one-modal-sizes {
  .frame-one-modal-container_inner_content_size-#{$size} {
    max-width: $breakpoint;
  }
}
