.business-page_top-bar {
  &_nav {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 0.625rem 1rem;
    min-height: 3.8125rem;

    & > div {
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }

    h4 {
      margin-bottom: 0;
    }

    &_back {
      cursor: pointer;
    }

    &_save {
      padding-left: 2rem;
      padding-right: 2rem;
    }

  }
}

.business-page_top-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .frame-one-button {
    height: 2rem;
    margin-top: 1.5rem;
  }
}