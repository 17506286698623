$frame-one-modal-padding: 1.75rem;

.frame-one-modal {
  border-radius: 16px;

  &_header {
    padding: $frame-one-modal-padding;

    &_title-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      white-space: break-spaces;

      &_title {
        @extend .sora_regular;
        font-size: 14px;
        margin-bottom: 0;
      }

      &_close-icon {
        @include svg-sizer(20px);
        cursor: pointer;
        margin-left: 1.5rem;
        transition: all 0.2s;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    &_children {
      padding-top: $frame-one-modal-padding;
    }
  }

  &_body {
    width: 100%;
    padding: $frame-one-modal-padding;
    padding-top: 0;
    white-space: normal;
  }

  &_footer {
    width: 100%;
    padding: $frame-one-modal-padding;
    padding-top: 0;
    display: inline-flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
