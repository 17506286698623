.brand-modal-map-swap-nav-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;

  &_img-con {
    width: 60px;
    aspect-ratio: 1;
    flex-shrink: 0;
    border-radius: 8px;
    overflow: hidden;
    background-color: $ui-gray;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_details {
    @include flex-row-item-defined-width-helper(1rem, 2, calc(100% - 60px));

    h3 {
      font-size: 16px;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-bottom: 5px;
    }

    &_btns {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.5rem;

      .frame-one-button {
        font-size: 12px;
        min-height: 0;
        padding: 5px 15px;
      }
    }
  }
}