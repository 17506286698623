.map-landing-bus-list-card-condensed {
  padding: 12px 30px;
  width: 100%;
  background-color: $white;
  border-top: 1px solid #E0E0E1;
  border-bottom: 1px solid #E0E0E1;

  &_top {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    &_icon-con {
      margin-top: 2px;

      svg {
        @include svg-sizer(18px);
      }
    }

    &_text-content {
      flex: 1; // fill up remaining width of parent container
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      &_title {
        font-size: 16px;
        margin-bottom: 0;
      }

      &_address {
        font-size: 13px;
        line-height: 15px;
        color: #403E48;
        margin-bottom: 0;
      }
    }

    &_btn-con {
      .frame-one-button {
        width: 110px;
      }
    }
  }

  &_btns {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 0.5rem;
    padding-left: 24px;

    .frame-one-button {
      font-size: 12px;
      min-height: 0;
      padding: 5px 15px;
    }
  }
}