.spinner {
  border: 9px solid $ui-gray;
  border-top: 9px solid $secondary-purple;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
