.brand-map-card {
  width: 100%;
  border-radius: 16px;
  padding: 1rem;
  background-color: $ui-gray;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &_branding {
    width: 100%;

    &_img {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: darken($ui-gray, 10%);

      &_map {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      svg {
        @include svg-sizer(30%);
        opacity: 50%;
      }
    }

    &_edit-btn {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
    }
  }

  &_content {
    &_name {
      margin-bottom: 0.5rem;
    }

    &_label {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600;

      &_locations {
        margin-bottom: 1rem;
      }
    }

    &_status {
      display: flex;
      gap: 0.35rem;

      svg {
        @include svg-sizer(20px);
      }

      &_draft {
        color: darken($warning, 30%);
      }

      &_published {
        color: darken($success, 10%);
      }
    }

    &_data-button {
      margin-bottom: 1rem;
      min-height: 0;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    & > p:last-child {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .brand-map-card {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;

    &_branding {
      width: 100%;
      max-width: 30%;
    }
  }
}