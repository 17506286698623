@import "../colors/colors.module";
@import "../mixins";

.map-creation-header {
  background-color: $dark-gray;
  color: white;

  &_inner {
    margin: 0 auto;
    max-width: 70rem;
    padding: 5.3rem 1rem 3.1rem;
    display: flex;
    gap: 6rem;
  }

  &_instructions {
    flex: 1;

    h3 {
      color: white;
    }
  }

  &_buttons {
    display: flex;
    gap: 1rem;

    button {
      height: fit-content;
    }

  }
}
