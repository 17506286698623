.manage-brands {
  @extend .container;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &_spacer-top {
    margin-bottom: 3rem;
  }

  &_content {
    &_loading {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 2rem;
    }

    &_brand-list {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: wrap;

      &_card {
        width: 100%;

        @include media-breakpoint-up(sm) {
          @include flex-row-item-defined-width-helper(1rem, 2, 50%);
        }

        @include media-breakpoint-up(md) {
          @include flex-row-item-defined-width-helper(1rem, 3, 33.33%);
        }

        @include media-breakpoint-up(lg) {
          @include flex-row-item-defined-width-helper(1rem, 4, 25%);
        }
      }

      &_empty {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        &_icon {
          @include svg-sizer(80px);
          color: rgba(black, 30%);
        }

        &_message {
          margin-top: 1rem;
          text-align: center;
        }
      }
    }
  }
}