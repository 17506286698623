.auth-header-logo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    height: 49px;
  }
}
