.image-upload-card {
  display: flex;
  gap: 2rem;

  &_image {
    flex: 1;

    &_photo {
      img {
        width: 100%;
      }
    }

    &_placeholder {
      background-color: $ui-gray;
      font-size: 4rem;
      padding: 2rem 1px 0;
      text-align: center;

      svg {
        vertical-align: top;
      }
    }
  }

  &_info {
    flex: 2;
  }

  &_description {
    margin-bottom: 1rem;
  }

  &_actions {
    display: flex;
    gap: 1rem;
  }
}
