@import "../colors/colors.module";
@import "../mixins";

.frame-one-paginator {
  border-left: 1px solid $ui-gray;
  border-right: 1px solid $ui-gray;
  border-bottom: 1px solid $ui-gray;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;

  &_results {
    flex: 1;
  }

  &_buttons-container {
    flex: 1;
    text-align: center;

    &_buttons {
      &_button {
        background: $ui-gray;
        border: 0;
        margin: 0 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;
        width: 2rem;
        height: 2rem;
        padding: 0;
        line-height: 2rem;
        cursor: pointer;

        &_active {
          color: $secondary-purple;
        }

        &-prev,
        &-next {
          background: none;

          svg {
            vertical-align: middle;
          }
        }
      }
    }
  }

  &_limiter-container {
    flex: 1;
    text-align: right;

    select {
      width: auto;
      display: inline-block;
    }
  }
}
