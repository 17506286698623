p {
  margin-top: 0;
}

h1 {
  @extend .sora_semi-bold;
  font-size: 32px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #08080A;

  @include media-breakpoint-up(md) {
    font-size: 48px;
  }
}

h2 {
  @extend .sora_semi-bold;
  font-size: 24px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #08080A;

  @include media-breakpoint-up(md) {
    font-size: 32px;
  }
}

h3 {
  @extend .sora_semi-bold;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #08080A;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}

h4 {
  @extend .sora_semi-bold;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #08080A;
}

h5 {
  @extend .sora_semi-bold;
  font-size: 16px;
  line-height: 21px;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #08080A;
}

h6 {
  @extend .sora_bold;
  font-size: 11px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 1rem;
  color: #08080A;
}
