@import "../colors/colors.module";
@import "../mixins";

.manage-businesses-page {
  @extend .container;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &_spacer-top {
    margin-bottom: 3rem;
  }

  &_header-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &_sort {
    &_button {
      @extend .reset-button;

      cursor: pointer;
      display: inline-block;
      padding: 0.5rem 1rem;
      margin-left: 1rem;
      background-color: $white;
      border: 1px solid $ui-gray;
      color: $black;
      border-radius: 1rem;

      &.is-active {
        background-color: $secondary-purple;
        border-color: $secondary-purple;
        color: $white;
      }

      svg {
        margin-left: 0.5rem;
      }
    }
  }

  &_list {
    @extend .reset-list;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    &_item {
      @include flex-row-item-defined-width-helper(1rem, 1, 100%);

      @include media-breakpoint-up(sm) {
        @include flex-row-item-defined-width-helper(1rem, 2, 50%);
      }

      @include media-breakpoint-up(md) {
        @include flex-row-item-defined-width-helper(1rem, 3, 33.3333%);
      }

      @include media-breakpoint-up(xxxl) {
        @include flex-row-item-defined-width-helper(1rem, 4, 25%);
      }
    }

    }
}

