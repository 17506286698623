/* To make changes to the FrameOneReactSelect input, see ./_frame-one-react-select.scss */
input, select, textarea {
  font-size: 1rem;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: $input-vertical-padding $input-horizontal-padding;
  color: #08080A;
  background-color: $white;
  background-clip: padding-box;
  border: $input-border-width solid #C7C7CA;
  border-radius: $input-border-radius;
  height: $frame-one-button-height;
  transition: all 0.3s;
  outline: none;

  &:enabled:hover {
    border-color: #08080A;
  }

  &:disabled {
    background-color: #F7F7F8;
    color: rgba(#08080A, 0.7);
  }

  &:focus {
    box-shadow: rgba($black, 0.2) 0 2px 15px 0;
    border-color: #08080A;
  }
}

select[multiple] {
  height: 10rem;
}

.f1-select-wrapper {
  position: relative;

  select {
    user-select: none;
    appearance: none;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }

  &_icon-container {
    aspect-ratio: 1;
    height: 70%;
    top: 15%;
    right: 5px;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;

    &_disabled {
      background-color: #F7F7F8;
    }

    svg {
      @include svg-sizer(18px);
    }
  }
}

textarea {
  height: 10rem;
}
