@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap');

@mixin sora($font-weight: 400) {
  font-family: "Sora", sans-serif;
  font-style: normal;
  font-weight: $font-weight;
}

.sora {
  @include sora;
}

.sora_regular {
  @include sora(400);
}

.sora_medium {
  @include sora(500);
}

.sora_semi-bold {
  @include sora(600);
}

.sora_bold {
  @include sora(700);
}
