.frame-one-autocomplete-input {
  position: relative;
  z-index: 1;

  &_results {
    position: absolute;
    width: 100%;
    background-color: $white;
    border: $input-border-width solid #C7C7CA;
    border-radius: $input-border-radius;
    overflow: hidden;
    box-shadow: 0 4px 6px 0 #C7C7CA;

    &_empty {
      padding: $input-vertical-padding $input-horizontal-padding;
    }

    &_list {
      list-style: none;
      padding: 0;
      margin: 0;

      &_item {
        button {
          background: none;
          cursor: pointer;
          border: 0;
          padding: $input-vertical-padding $input-horizontal-padding;
          width: 100%;
          text-align: left;

          &:hover {
            background-color: $ui-gray;
          }
        }
      }
    }
  }
}
