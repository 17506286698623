/*
Use for places in the UI where you want n number of items in a row to be equal width with NO wrapping,
This mixin will determine the width per item. Honestly, flex: 1 is probably just as good?
 */
@mixin flex-row-item-width-helper($gap, $totalNumberOfSiblings) {
  width: calc((100% - (calc(#{$totalNumberOfSiblings} - 1) * #{$gap})) / #{$totalNumberOfSiblings});
}

/*
Use this mixin when you want to define sizes of a layout - while defining the gap between items ($gap) & each section's width ($proposedWidth),
or if you want to have a flex-wrapped list of a dynamic number of total items, while controlling the number of items per row ($siblingsPerRow).
 */
@mixin flex-row-item-defined-width-helper($gap, $siblingsPerRow, $proposedWidth) {
  width: calc((#{$proposedWidth} - (calc((calc(#{$siblingsPerRow} - 1) / #{$siblingsPerRow}) * #{$gap}))));
}
