@import "../colors/colors.module";
@import "../mixins";

.account-page {
  @extend .container;
  margin-top: 3rem;
  margin-bottom: 3rem;

  &_title {
    text-transform: capitalize;
  }

  &_nft-collections {
    &_title {
      margin-bottom: 0.5rem;
    }

    &_loading {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
    }

    &_nfts {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.75rem;

      &_coll {
        @include flex-row-item-defined-width-helper(0.75rem, 1, 100%);

        @include media-breakpoint-up(sm) {
          @include flex-row-item-defined-width-helper(0.75rem, 2, 50%);
        }

        @include media-breakpoint-up(md) {
          @include flex-row-item-defined-width-helper(0.75rem, 3, 33.3333%);
        }

        @include media-breakpoint-up(xxxl) {
          @include flex-row-item-defined-width-helper(0.75rem, 4, 25%);
        }
      }

      &_empty-msg {
        width: 100%;
        text-align: center;
        font-style: italic;
      }
    }
  }
}

.account-page-loading-legal {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.stripe-connected-accounts {
  &_create-button {
    margin-bottom: 2rem;
  }

  &_table {
    width: 100%;

    th, td {
      border-bottom: 1px solid $ui-gray;
      padding: 0.5rem;
    }

    &_row {
      &_payouts {
        text-align: center;
      }

      &_manage {
        text-align: center;
      }
    }
  }
}
