.business-page_header {
  position: relative;

  &_image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-gray;
    height: 11.875rem;
    @include media-breakpoint-up(lg) {
      height: 13.75rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &_background-icon {
      @include svg-sizer(2rem);
      color: $medium-gray;
    }

    &_button-container {
      position: absolute;
      width: 100%;
      top: 2rem;
      display: flex;
      gap: 0.75rem;
      justify-content: flex-end;
      align-items: center;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &_button {
      z-index: 1;
      border: none
    }

    &_remove-button {
      @include svg-sizer(1.3rem);
      color: $white;
      cursor: pointer;
      filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.4));
    }
  }

  &_logo-container {
    position: relative;

    & > div {
      position: absolute;
      z-index: 1;
      top: -3rem;
    }
    span {
      cursor: pointer;
      color: $secondary-purple;
      font-weight: 500;
      font-size: 0.875rem;
    }
  }

  &_logo {

    border-radius: 1rem;
    overflow: hidden;


    border: 0.1875rem solid $white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-gray;
    box-shadow: 0 0.25rem 0.5rem -0.125rem rgba(16, 24, 40, 0.03), 0 0.25rem 1rem -0.25rem rgba(16, 24, 40, 0.08);
    width: 4.5rem;
    height: 4.5rem;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 50% 50%;
      vertical-align: top;
    }

    &_background-icon {
      @include svg-sizer(1.25rem);
      color: $medium-gray;
    }
  }

  &_info {
    margin-top: 3.5rem !important;

    h2 {
      margin-bottom: 0;
    }

    &_description {
      color: #616169;
      font-size: 0.875rem;

      span {
        cursor: pointer;
        color: $secondary-purple;
        font-weight: 500;
      }
    }
    &_description-update {
      display: flex;
      gap: 1rem;

      textarea {
        max-height: unset;
        min-height: unset;
        height: unset;
        @extend .sora;
        font-size: 0.875rem;
      }
    }
  }
}
