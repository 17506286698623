@import "../colors/colors.module";
@import "../mixins";

.restaurant-map-landing-page-padding-adjuster {
  height: 100%;
}

.preview-logo {
  padding: 2rem 6rem;

  img {
    width: 7.625rem;
  }

}

.restaurant-map-landing {
  $root: &;
  position: relative;
  overflow-x: clip;

  &_content {
    #restaurant-map-landing_content_claim-rewards {
      @extend .owners-narrow_bold;
      font-size: 2.5rem;
      font-weight: 800;
      line-height: 100%;
      margin-bottom: 1rem;
      scroll-margin-top: 6rem; /* height of top bar*/
      color: $white;

      #{$root}.theme-DARK & {
        color: $black;
      }

      @include media-breakpoint-up(sm) {
        scroll-margin-top: 4.125rem;
      }
    }

    &_wrapper {
      max-width: 80rem;
      margin: 0 auto;
      padding: 0 1.5rem 5rem;

      @include media-breakpoint-up(sm) {
        padding: 0 5.375rem 5rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 6.25rem 5rem;
      }
    }
  }

  &_main-promo {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    padding: 1.25rem;

    &_no-bg {
      #{$root}.theme-LIGHT & {
        border: 0.063rem solid rgba(255, 255, 255, 0.20);
        background-color: rgba(255, 255, 255, 0.10);
      }

      #{$root}.theme-DARK & {
        border: 0.063rem solid $light-gray;
        box-shadow: 0 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03), 0 0.25rem 1rem -0.25rem rgba(16, 24, 40, 0.08);
      }

    }

    @include media-breakpoint-up(lg) {
      display: flex;
      gap: 2.5rem;
      padding: 2rem;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      border-radius: 1rem;
      background: linear-gradient(0deg, $black 0%, transparent 100%);

      #{$root}.theme-DARK & {
        background: linear-gradient(0deg, $white 0%, transparent 75%);
      }

      @include media-breakpoint-up(lg) {
        background: linear-gradient(270deg, $black 0%, transparent 100%);

        #{$root}.theme-DARK & {
          background: linear-gradient(270deg, $white 0%, transparent 75%);
        }
      }
    }

    &_hero {
      position: relative;
      z-index: 1;
      margin-bottom: 1rem;

      @include media-breakpoint-up(lg) {
        flex: 1;
        margin-bottom: 0;
      }

      img {
        width: 100%;
        height: 14rem;
        object-fit: cover;
        object-position: 50% 50%;
        vertical-align: top;
        border-radius: 0.25rem;

        @include media-breakpoint-up(lg) {
          height: auto;
        }
      }
    }

    &_details {
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(lg) {
        flex: 1;
      }
    }

    &_pill {
      color: $white;
      font-size: 0.75rem;
      background-color: $secondary-purple;
      padding: 0.25rem 0.75rem;
      border-radius: 1rem;
      display: inline-block;
      margin-bottom: 1rem;

      &.unavailable {
        background-color: $dark-gray;
        color: $medium-gray;
      }
    }

    &_title {
      @extend .owners-xnarrow_bold;
      color: $secondary-purple;
      font-size: 4rem;
      margin-bottom: 0;
      line-height: 1;
    }

    &_tagline {
      @extend .sora_semi-bold;

      color: $white;
      font-size: 1.125rem;

      #{$root}.theme-DARK & {
        color: $black;
      }
    }

    &_redeem {
      margin-bottom: 1rem;

      .frame-one-button {
        width: 100%;
        background: linear-gradient(90deg, #804FD3 0%, #657AE1 100%);
      }
    }

    &_description {
      color: $medium-gray;
      font-size: 0.75rem;

      #{$root}.theme-DARK & {
        color: $dark-gray;
      }
    }
  }

  &_other-rewards {
    margin: 3.75rem 0;
    color: $white;

    #{$root}.theme-DARK & {
      color: $black;
    }

    @include media-breakpoint-up(sm) {
      margin: 5rem 0;
    }

    p,
    h3,
    h4{
      margin-bottom: 0;
      color: inherit;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2rem 0 1.5rem;

      h3 {
        @extend .owners-narrow_bold;
        font-size: 2.5rem;
        font-weight: 800;
        line-height: 100%;
      }

      p {
        color: $medium-gray;
      }
    }

    &_list {
      @extend .reset-list;
      @extend .hide-scrollbar;
      display: flex;
      gap: 1.25rem;
      width: 100%;
      overflow-y: hidden;
      max-height: fit-content;
      padding: 0.5rem 0;

      &_item {
        background: linear-gradient(96deg, rgba(255, 255, 255, 0.10) 1.49%, rgba(255, 255, 255, 0.04) 105.22%);
        border-radius: 1rem;
        box-shadow: 0 0.25rem 0.5rem -0.125rem rgba(16, 24, 40, 0.03), 0 0.25rem 1rem -0.25rem rgba(16, 24, 40, 0.08);
        border: 0.0625rem solid $lighter-gray;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1.25rem;
        padding: 1.5rem !important;

        @include flex-row-item-defined-width-helper(1.25rem, 1, 100%);
        min-width: 85%;

        @include media-breakpoint-up(md) {
          @include flex-row-item-defined-width-helper(1.25rem, 2, 50%);
          min-width: 42%;
        }

        @include media-breakpoint-up(xl) {
          @include flex-row-item-defined-width-helper(1.25rem, 3, 33.33%);
          min-width: unset;
          flex-shrink: 0;
        }

        #{$root}.theme-DARK & {
          border: 0.0625rem solid rgba($white, 0.20);
        }

        #{$root}:not(.dark-mode) {
          .frame-one-button {
            background-color: $light-lilac;
            color: $secondary-purple;
          }
        }

        .frame-one-button {
          width: 100%;
          font-size: 1rem;
        }

        &_inner {
          h4 {
            font-size: 1.125rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &_expiry {
            color: $medium-gray !important;
            font-size: 0.75rem;
            line-height: normal;
            min-height: 1.125rem;
            margin-top: 0.1875rem;
          }

          &_description {
            margin-top: 1rem;
            line-height: 135%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

        }
      }
    }
  }

  &_login {
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background-color: rgba(255, 255, 255, 0.10);
    padding: 3.75rem 1.875rem;

    &_inner {
      max-width: 36rem;
      margin: 0 auto;
      text-align: center;


      h4 {
        color: $white;
        line-height: 1;
        margin-bottom: 2.5rem;

        #{$root}.theme-DARK & {
          color: $black;
        }
      }

      p {
        color: $medium-gray;
        font-size: 0.75rem;
        margin: 0;
        line-height: 1.25;
      }

      input {
        margin-bottom: 1.25rem;
        border-color: $medium-gray;
        background: linear-gradient(91deg, rgba(255, 255, 255, 0.20) 0.11%, rgba(255, 255, 255, 0.14) 104.56%);
        color: white;

        #{$root}.theme-DARK & {
          color: $black;
        }
      }

      .frame-one-button {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  &_map {
    margin-top: 4rem;
    margin-bottom: 5rem;

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 30rem auto;
      grid-template-areas:
        "header map"
        "handoff map"
        "search map"
        "list map";
      column-gap: 1.25rem;
      row-gap: 1.25rem;
    }

    &_header {
      display: flex;
      gap: 1.25rem;
      margin-bottom: 1.25rem;

      @include media-breakpoint-up(lg) {
        grid-area: header;
        margin-bottom: 0;
      }

      &_image {
        width: 5rem;

        img {
          border-radius: 50%;
          width: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: 50% 50%;
          vertical-align: top;
        }
      }

      &_name {
        color: $white;
        margin: 0;

        #{$root}.theme-DARK & {
          color: $black;
        }
      }

      &_counter {
        color: $white;
        margin: 0;

        #{$root}.theme-DARK & {
          color: $black;
        }
      }
    }

    &_handoff {
      margin-bottom: 1.25rem;

      @include media-breakpoint-up(lg) {
        grid-area: handoff;
        margin-bottom: 0;
      }

      .frame-one-switch-input {
        background-color: $dark-gray;
        height: 3.125rem;

        #{$root}.theme-DARK & {
          background-color: $light-gray;
        }

        &_option {
          color: $white;

          #{$root}.theme-DARK & {
            color: $black;
          }

          &.is-active {
            color: $dark-gray;
          }
        }
      }
    }

    &_search {
      margin-bottom: 1.25rem;

      @include media-breakpoint-up(lg) {
        grid-area: search;
        margin-bottom: 0;
      }

      input {
        border: 0;
        background-color: $dark-gray;
        color: $white;

        #{$root}.theme-DARK & {
          background-color: $light-gray;
          color: $black;
        }
      }

      svg {
        color: $medium-gray;
      }

      .search-input_clearer {
        background: none;
      }
    }

    &_render {
      height: 30rem;
      margin-bottom: 1.25rem;
      border-radius: 1rem;
      overflow: hidden;

      @include media-breakpoint-up(lg) {
        grid-area: map;
        height: auto;
        margin-bottom: 0;
      }
    }

    &_list {

      @include media-breakpoint-up(lg) {
        grid-area: list;
        overflow: auto;
        height: 24rem;
        padding-right: 0.5rem;
      }

      &::-webkit-scrollbar {
        width: 0.5rem;
      }

      &::-webkit-scrollbar-track {
        background-color: rgba($medium-gray, 0.2);
        border-radius: 0.25rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($medium-gray, 0.5);
        border-radius: 0.25rem;
      }

      &_item {
        margin-bottom: 0.62rem;
      }
    }
  }

  &_separator {
    height: 1px;
    background-color: rgba($dark-gray, 0.6);
    margin-bottom: 4rem;

    #{$root}.theme-DARK & {
      background-color: $light-gray;
    }
  }

  &_other-promos {
    &_title {
      @extend .owners-narrow_bold;

      text-transform: uppercase;
      color: $white;
      font-size: 2.5rem;

      #{$root}.theme-DARK & {
        color: $black;
      }
    }

    &_list {
      @extend .reset-list;

      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem;

      &_item {
        @include flex-row-item-defined-width-helper(1.25rem, 2, 50%);

        margin-bottom: 1.25rem;

        @include media-breakpoint-up(md) {
          @include flex-row-item-defined-width-helper(1.25rem, 3, 33.33%);
        }

        &-0 {
          width: 100%;

          @include media-breakpoint-up(md) {
            @include flex-row-item-defined-width-helper(1.25rem, 3, 33.33%);
          }
        }

        img {
          width: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: 50% 50%;
          vertical-align: top;
          border-radius: 1rem;
        }
      }
    }
  }

  .frame-one-button.order-btn-in-progress {
    color: $medium-gray;
    background: $dark-gray;
  }
}

