.restaurant-map-landing {
  $root: &;

  &_header {

    &_banner {
      margin-top: -0.3rem;
      position: relative;
      height: 17.9375rem;
      background-size: cover;
      background-position: center;

      @include media-breakpoint-up(md) {
        height: 19.375rem;
      }

      @include media-breakpoint-up(lg) {
        height: 23.4375rem;
      }

      .background-triangle {
        width: 0;
        height: 0;
        position: absolute;
        border-top: 5.5625rem solid transparent;

        @include media-breakpoint-down(xs) {
          border-top-width: 4rem;
        }

        &.left {
          bottom: 0;
          left: 0;
        }

        &.right {
          bottom: 0;
          right: 0;
        }
      }

      &_angle-lines {
        position: absolute;
        bottom: -1.25rem;
        stroke: $white;

        #{$root}.theme-DARK & {
          stroke: $black;
        }

        @include media-breakpoint-down(xs) {
          height: 4rem;
        }
      }

    }

    &_text-content {
      padding: 3.75rem 1.5rem 3.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include media-breakpoint-up(md) {
        padding: 5rem 5.375rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 7rem 6.25rem;
      }

      h1 {
        @extend .owners-narrow_bold;
        font-size: 3rem;
        font-weight: 800;
        text-transform: uppercase;
        line-height: 100%;
        max-width: 800px;
        margin: 0;
        color: $white;

        #{$root}.theme-DARK & {
          color: $black;
        }

        @include media-breakpoint-up(md) {
          font-size: 4rem;
          line-height: 94%;
        }

        @include media-breakpoint-up(lg) {
          font-size: 5rem;
        }
      }

      & > p {
        @include gradient-text(linear-gradient(90deg, $brighter-purple 0%, $blue-purple 100%));
        font-weight: 600;
        font-size: 1.5rem;
        margin-top: 2.5rem;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          margin-top: 5rem;
        }

        @include media-breakpoint-up(lg) {
          margin-top: 1.875rem;
          font-size: 1.5rem;
        }

        &.has-description {
          margin-top: 1.875rem;
        }

      }

      &_description {
        margin-top: 1.875rem;
        color: $medium-gray;
        max-width: 56.25rem;
        text-align: center;
        line-height: 135%;
        white-space: pre-wrap;

        #{$root}.theme-DARK & {
          color: $black;
        }
      }

      &_scroll-container {
        margin-top: 5rem;
        cursor: pointer;
      }

    }
  }
}
