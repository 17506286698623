.page-padding-adjuster {
  height: 100%;

  &_active {
    transition: all 0.5s;

    @media screen and (min-width: $f1-sidebar-docked-at) {
      @if ($f1-sidebar-docked-at >= 0) {
        padding-left: $f1-sidebar-width-docked;
      }
    }
  }
}

.devour-hamburger-helper {
  @extend .container;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  svg {
    @include svg-sizer(24px);
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (min-width: $f1-sidebar-docked-at) {
    display: none;
  }
}

.f1-sidebar {
  &_overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 0;
    height: 0;
    background-color: rgba(black, 0);
    transition: width 0s, height 0s, background-color 0.5s;

    &_open {
      width: 100vw;
      height: 100vh;
      background-color: rgba(black, 0.5);
    }

    &_closing {
      width: 100vw;
      height: 100vh;
      background-color: rgba(black, 0);
    }
  }

  &_bar {
    position: fixed;
    z-index: 2;
    top: 0;
    left: calc(#{$f1-sidebar-width} * -1);
    width: $f1-sidebar-width;
    height: 100vh;
    background-color: $primary-purple;
    transition: left 0.5s, width 0.5s;

    @media screen and (min-width: $f1-sidebar-docked-at) {
      @if ($f1-sidebar-docked-at >= 0) {
        width: $f1-sidebar-width-docked;
      }
    }
  }

  &_docked {
    .f1-sidebar_bar {
      left: 0;
    }
  }

  &_docking-disabled {
    .f1-sidebar_bar {
      left: calc(#{$f1-sidebar-width_docked} * -1);
    }
  }
}
