.business-page_payout-settings {
  display: flex;
  gap: 3rem;
  padding: 1.5rem 0 5rem;

  & > div {
    flex: 1;
  }

  p,
  h4 {
    margin-bottom: 0;
  }

  &_account {
    &_header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      margin-bottom: 1.5rem;

      p {
        font-size: 0.875rem;
        color: $slate-gray;
      }
    }

    &_review {
      margin-top: 1.625rem;
      padding: 1.125rem;
      border-radius: 1rem;
      border: 0.0625rem solid $lighter-gray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;

      &_no-account {
        text-align: center;
        margin-bottom: 0;
      }

      &_info {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5rem;


        &_section {
          justify-self: start;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          p {
            @extend .sora_medium;
            color: $darker-gray;
          }

          span {
            font-size: 0.875rem;
            color: $slate-gray;
          }

          &_manager {
            display: flex;
            align-items: center;
            gap: 0.4375rem;
            padding: 0.437rem 1rem;
            border: 0.0625rem solid $lighter-gray;
            border-radius: 1rem;
          }
        }
      }

      .frame-one-button {
        margin: 0 auto;
      }
    }

  }

  &_payouts {
    &_no-account {
      border-radius: 1rem;
      border: 0.0625rem solid $lighter-gray;
      display: flex;
      justify-content: center;
      align-items: center;
      
      p {
        text-align: center;
      }
    }
  }
}

.business-create_payout-settings {
  padding: 1.5rem 0 5rem;

  .business-page_payout-settings_account {
    width: calc(50% - 0.5rem);
  }
}