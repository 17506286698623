@import "../colors/colors.module";
@import "../mixins";

.map-landing-bus-list-card {
  display: grid;
  column-gap: 1.25rem;
  row-gap: 0.5rem;
  border: 2px solid transparent;
  padding: 1.25rem;
  border-radius: 1rem;
  align-items: center;
  background-color: rgba($dark-gray, 0.5);
  grid-template-areas:
    "image details";
  grid-template-columns: 6.25rem auto;
  cursor: pointer;

  .theme-DARK & {
    background-color: $light-gray;
  }

  &.is-active {
    border-color: $secondary-purple;
    background-color: $black;

    .theme-DARK & {
      background-color: $white;
    }

    &.is-open {
      grid-template-areas:
        "image details"
        "button button";

      @include media-breakpoint-up(md) {
        grid-template-areas:
          "image details"
          "image button";
      }
    }
  }

  &_image {
    grid-area: image;

    img {
      border-radius: 1rem;
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: 50% 50%;
      vertical-align: top;
    }
  }

  &_content {
    grid-area: details;

    &_title-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 0.5rem;

      &_title {
        @extend .sora_semi-bold;

        font-size: 1.125rem;
        color: $white;
        margin-bottom: 0;

        .theme-DARK & {
          color: $black;
        }
      }
    }

    &_address {
      @extend .sora;

      margin-bottom: 0;
      color: white;
      font-size: 1rem;
      line-height: 1.35rem;

      .theme-DARK & {
        color: $black;
      }
      @include media-breakpoint-up(lg) {
        max-width: 19rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &_btn-con {
    grid-area: button;

    .frame-one-button {
      width: 100%;
      border-radius: 1.5rem;
      background: linear-gradient(90deg, #804FD3 0%, #657AE1 100%);

      @include media-breakpoint-up(md) {
        width: fit-content;
      }
    }
  }
}
