@import "../colors/colors.module";
@import "../mixins";

.create-map-page {

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    margin-bottom: 0;
    font-size: 1rem;
  }

  &_loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_content {
    max-width: 60rem;
    margin: 0 auto;
    padding-bottom: 3rem;
  }

  &_container {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;

    &_inner {
      padding: 2.5rem;
      background-color: white;
      border-radius: 1rem;

      p {
        color: $medium-gray;
      }
    }
  }

  &_optional {
    @extend .sora_regular;

    display: inline-block;
    color: $medium-gray;
    background-color: $light-gray;
    padding: 0 0.5rem;
    border-radius: 1rem;
    margin-left: 0.5rem;
    font-size: 0.75rem;
    vertical-align: middle;
  }

  &_img-remove-icon {
    @include svg-sizer(1.3rem);
    color: $white;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
    filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.4));
  }

  &_automint-toast {

    &_field {
      p {
        margin-bottom: 0;
        font-size: 0.75rem;
      }

      &:first-of-type {
        margin-bottom: 1rem;
      }
    }
  }

  input,
  textarea {
    background-color: $light-gray;
    border: none;
    height: 3rem;
    font-size: 0.875rem;

    &::placeholder {
      color: $medium-gray;
    }
  }

  textarea {
    font-family: Arial, monospace;
    padding: 1.1rem !important;
    max-width: 100%;
  }
}

