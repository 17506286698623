.brand-permission-card {
  width: 100%;
  border-radius: 16px;
  padding: 1rem;
  background-color: $ui-gray;

  &_img {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_name {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_btns {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .frame-one-button {
      width: 100%;
    }
  }
}