$frame-one-global-loading-size: 14vw;
$frame-one-global-loading-min-size: 140px;

.loading-manager {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(black, 0.6);

  &_svg {
    @include svg-sizer($frame-one-global-loading-size);
  }
}
