.create-map-page_content_data_others {
  margin: 1.5rem 0;

  p, h4, h5 {
    margin: 0;
  }

  &_promos {
    margin-top: 1.25rem;
    background-color: $white;
    padding: 2.5rem;
    border-radius: 1rem;

    &_top {
      margin-bottom: 1.9375rem;

      p {
        margin-top: 0.625rem;
        font-size: 0.875rem;
        color: $medium-gray;
      }
    }

    &_preview {
      .small-text {
        color: $medium-gray;
        font-size: 0.75rem;
        margin-bottom: 0.1875rem;
      }

      &_list {
        display: flex;
        gap: 1.25rem;
        justify-content: center;

        .promos_preview_list_item{
          @include flex-row-item-defined-width-helper(1.25rem, 3, 33.33%);
          display: flex;
          flex-direction: column;
          gap: 0.625rem;

          &_img-container {
            width: 100%;
            aspect-ratio: 1;
            position: relative;
            overflow: hidden;
          }

          &_input {
            border: none;
            background-color: $light-gray;
            font-size: .875rem;

            &:disabled {
              cursor: not-allowed;
            }
          }
        }
      }
    }

  }
}