.demo-page {
  @extend .container;
  padding: 2.5rem 0;

  &_spaced-components {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    &_row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
  }
}
