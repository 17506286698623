@import "../colors/colors.module";
@import "../mixins";

.business-create-page,
.business-update-contact-page {
  @extend .mobile-force-container;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &_spacer-top {
    margin-bottom: 2rem;
  }

  &_title {
    line-height: 2;
    text-align: center;
    font-size: 1.5rem;
  }

  &_field {
    margin-bottom: 1rem;
  }

  &_submit-button {
    width: 100%;
  }
}
