.frame-one-date-picker {
  height: 42px !important; // todo variable
  width: 150px;

  .rs-picker-default {
    z-index: 0 !important;
  }

  .rs-picker-toggle {
    z-index: 0 !important;
    height: 100%;
    border-radius: 8px; // todo variable
    border-color: darken($ui-gray, 50%) !important;

    &:hover {
      border-color: $secondary-purple !important;
    }
  }

  .rs-picker-toggle-value {
    color: $primary-purple !important;
  }
}
