.business-page_users {
  padding: 1.5rem 0 3rem;

  .frame-one-table-container_table {
    width: 100%;

    td {
      span {
        padding: 0.0625rem 0.375rem;
        border-radius: 0.375rem;
        font-size: 0.875rem;

        &.has-permission {
          background-color: #CCF5DA;
          color: #297837;
        }

        &.no-permission {
          background-color: $ui-gray;
          color: #C7C7CA;
        }
      }
    }
  }
  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: 0.0625rem solid $ui-gray;
    border-right: 0.0625rem solid $ui-gray;
    border-top: 0.0625rem solid $ui-gray;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 1rem;
  }

  &_update {
    display: flex;
    align-items: center;
    color: $secondary-purple;
    cursor: pointer;
    font-size: 0.875rem;
    gap: 0.5rem;

    &.is-disabled {
      cursor: not-allowed;
    }
  }
}