.wallet-connect-registry-modal {
  &_body {
    padding-top: $frame-one-modal-padding;
    &_prompt-connect {
      &_web3-btn-con {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
      }
    }

    &_prompt-signing {
      &_input {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      &_btn {
        width: 100%;
      }

      &_error {
        margin-top: 1rem;
        color: $error;
        word-break: break-word;
      }
    }

    &_loading-signing {
      &_spinner-con {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
      }

      &_status {
        text-align: center;
        margin-bottom: 0;
      }
    }

    &_finished {
      &_check-con {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;

        &_check {
          @include svg-sizer(90px);
          color: $success;
        }
      }

      &_status {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}
